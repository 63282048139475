import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../shared/PrivateRoute'
import Dashboard from './index'
import OrderNew from './order/new'
import Login from './login'
import RegisterPage from './register'
import { CookiesProvider } from 'react-cookie'
import PublicShiptmentStatus from './shipmentstatus/[trackId]'
const Portal = () => {
  return (
    <div>
      <CookiesProvider>
        <Router basepath="/">
          <Login path="/login" />
          <PrivateRoute path="/" default component={Dashboard} />
          <PrivateRoute path="/order/new" default component={OrderNew} />
          <PrivateRoute path="/register" component={RegisterPage} />
          <PrivateRoute path="/shipmentstatus/:id" component={PublicShiptmentStatus} />
        </Router>
      </CookiesProvider>
    </div>
  )
}

export default Portal
