import React from "react";
import MainLayout from "../components/mainlayout";
// import UserAuth from '../api/user';
// import { navigate } from "gatsby";
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  // if (!UserAuth.hasUserSession()) {
  //   navigate("/login")
  //   return null
  // }
  return (
    <MainLayout>
      <Component {...rest} />
    </MainLayout>
  )
};
export default PrivateRoute; 
